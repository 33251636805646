.adminHead {
  display: flex;
  flex-direction: row;
  .sortForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    select {
      height: max-content;
      font-size: 16px;
      margin: 0 10px;
      border: 2px solid gray;
    }
    input {
      height: 40px;
    }
    button {
      width: 100%;
      height: max-content;
      padding: 5px 10px;
      border: 2px solid gray;
      background-color: white;
      margin: 0 10px;
    }
  }
}
.updateProduct {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  margin: auto;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
  background: $secondary-color;
  overflow-y: scroll !important;
  height: 100%;
  .updateProductPopup {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 50%;
    margin: auto;
    position: fixed;
    top: 5%;
    left: 25%;
    z-index: 1000;
    box-shadow: 0px 0px 200px black;
    background: white;
    overflow-y: scroll !important;
    max-height: 90%;

    .updateProductHeading {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      h2 {
        text-align: center;
      }
      button {
        float: right;
        border: none;
        background-color: white;
        position: absolute;
        right: 25px;
      }
    }

    form {
      .addVaritaions {
        border: 2px solid gray;
        margin: 25px 0;
        width: max-content;
        padding: 10px 100px;
        background: white;
        color: black;
        font-size: 17px;
      }
      label {
        display: flex;
        flex-direction: column;
        margin: 20xp 0;
        button {
          border: 1px solid gray;
          margin: 10px 0 0;
        }
      }
      input {
        height: unset;
      }
      textarea {
        height: 100px;
      }
      .variationInput {
        height: 700px;
      }
      button {
        width: 200px;
        height: unset;
        border: 2px solid gray;
        background-color: white;
        color: black;
        height: 45px;
        font-size: 17px;
      }
    }
  }
}
.newProduct {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  margin: auto;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
  background: $secondary-color;
  overflow-y: scroll !important;
  height: 100%;
  .newProductPopup {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 50%;
    margin: auto;
    position: fixed;
    top: 5%;
    left: 25%;
    // box-shadow: 0px 0px 200px black;
    background: white;
    overflow-y: scroll !important;
    max-height: 90%;

    .addProductBtn {
      border: 2px solid gray;
      background-color: white;
      padding: 20px;
      font-size: 25px;
    }

    .newProductHeading {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      h2 {
        text-align: center;
      }
      button {
        float: right;
        border: none;
        background-color: white;
        position: absolute;
        right: 25px;
      }
    }

    form {
      .addVaritaions {
        border: 2px solid gray;
        margin: 25px 0;
        width: max-content;
        padding: 10px 100px;
        background: white;
        color: black;
        font-size: 17px;
      }
      label {
        display: flex;
        flex-direction: column;
        margin: 20xp 0;
        button {
          border: 1px solid gray;
          margin: 10px 0 0;
        }
      }
      input {
        height: unset;
      }
      textarea {
        height: 100px;
      }
      .variationInput {
        height: 700px;
      }
      button {
        width: 200px;
        height: unset;
        border: 2px solid gray;
        background-color: white;
        color: black;
      }
    }
  }
}
