.book-session {
  display: flex;
  padding: 20px;
  .row {
    justify-content: center;
  }
}
.meeting-embed {
  margin: auto;

  .calendly-inline-widget {
    overflow: hidden !important;
    width: 1000px;
    max-width: 1000px;
    height: 700px;
    justify-content: center;
    @media screen and (max-width: 1023px) {
      width: 90vw;
      max-width: 720px;
      height: 860px;
      padding: 50px 0;
    }
    @media #{$xs-layout} {
      width: 90vw;
      min-height: 800px;
      justify-content: center;
      padding: 20px 0;
    }
    @media screen and (max-width: 576px) {
      max-width: 540px;
    }
  }
}

.meet-info-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    margin: 20px 0;
  }
}

.home-meeting-container {
  padding: 10px 78px 70px;
  @media screen and (max-width: 570px) {
    padding: 10px 0px 70px;
  }
  .home-meeting-div {
    display: flex;
    padding: 25px;
    background: #050e26;
    color: white;
    border-radius: 10px;

    @media screen and (max-width: 570px) {
      flex-direction: column;
    }
    .home-meeting-line {
      flex: 0.7;
      font-size: 1.25rem;
      line-height: 2.3;
      padding-right: 20px;
      @media screen and (max-width: 990px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: 760px) {
        font-size: 1.3rem;
      }
      @media screen and (max-width: 570px) {
        padding: 0px;
        padding-bottom: 30px;
      }
    }
    .home-meeting-btn {
      flex: 0.3;
      display: flex;
      justify-content: center;
      margin: auto;
      @media screen and (max-width: 570px) {
        justify-content: center;
      }
      button {
        font-weight: bold;
        font-size: 17px;
        line-height: 1;
        width: 100%;
        z-index: 99;
        display: inline-block;
        padding: 10px 15px 10px;
        text-transform: uppercase;
        color: $theme-color;
        border: none;
        border-radius: 10px;
        background: none;
        background-color: #ffc107;
      }
      button:hover {
        background-color: rgb(202, 202, 202);
      }
      a {
        color: unset;
        font-size: 20px;
      }
    }
  }
}
