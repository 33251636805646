.chatbot-container {
  .rsc > div {
    background: #f5f8fb;
    border-radius: 10px;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 15%);
    font-family: monospace;
    overflow: hidden;
    position: fixed;
    bottom: initial;
    bottom: 180px;
    right: 20px;
    /* left: initial; */
    width: 355px;
    height: 520px;
    z-index: 999;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transition: -webkit-transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    @media #{$xs-layout} {
      bottom: 55px !important;
      z-index: 10001;
    }

    h2 {
      color: unset;
    }
  }
}
.chat-icon {
  position: fixed;
  z-index: 9811 !important;
  right: 20px;
  bottom: 120px;
  /* visibility: hidden; */
  overflow: hidden;
  width: 50px;
  height: 50px;
  text-align: center;
  /* opacity: 0; */
  color: #fff;
  border: none;
  border-radius: 50px;
  background: none;
  background-color: #050e26;
  @media #{$xs-layout} {
    width: 40px;
    height: 40px;
    bottom: 10px;
  }
  & i {
    font-size: 22px;
    line-height: 50px;

    display: block;
    @media #{$xs-layout} {
      font-size: 16px;
      line-height: 40px;
    }
  }
}
